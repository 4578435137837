[data-marqy] {
  @apply relative overflow-hidden;

  * {
    @apply whitespace-nowrap;
  }
}

[data-marqy-inner] {
  @apply flex;

  [data-direction='right'] & {
    @apply justify-end;
  }

  @media (prefers-reduced-motion) {
    @apply overflow-x-scroll;
  }
}

[data-marqy-content] {
  @apply flex;
  flex: 1 0 auto;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  will-change: transform;

  [data-direction='left'] & {
    animation-name: marqueeL;
  }

  [data-direction='right'] & {
    animation-name: marqueeR;
  }

  [data-pause-on-hover]:hover & {
    animation-play-state: paused;
  }

  @media (prefers-reduced-motion) {
    animation: none !important;
  }
}

[data-marqy-item] {
  flex: 1 0 auto;
}

@keyframes marqueeL {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes marqueeR {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.marquee {
  &--item {
    @apply flex;
  }

  &--text {
    @apply block p-8 text-5xl leading-none;
  }

  &--photo {
    @apply relative w-32 sm:w-48 px-8 py-4 sm:px-12 sm:py-8;

    img {
      html:not([data-theme='dark']) & {
        filter: invert(1);
      }
    }
  }

  &--product {
    @apply flex flex-col -my-px;
    width: calc(80vw - 2rem);
    max-width: 28rem;

    * {
      @apply whitespace-normal;
    }

    .product-card {
      @apply flex-1;
    }
  }
}
